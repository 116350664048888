
function offset(el) {
  box = el.getBoundingClientRect();
  docElem = document.documentElement;
  return {
    top: box.top + window.pageYOffset - docElem.clientTop,
    left: box.left + window.pageXOffset - docElem.clientLeft
  };
}

function scrollTop(el, value) {
  if (value === undefined) {
    return el.pageYOffset;
  } else {
    if (el === window || el.nodeType === 9) {
      el.scrollTo(el.pageXOffset, value);
    } else {
      el.pageYOffset = value;
    }
  }
}

const menuToggle = document.getElementById( 'menu-toggle' );
const mobileMenu = document.getElementById( 'mobile-menu' );
const header = document.getElementById( 'header' );
if ( menuToggle && mobileMenu ) {
  var windowMenuScrollTop = scrollTop(window);
  menuToggle.addEventListener('click', (e) => {
    if ( header.classList.contains( 'menuOpen') ) {
      header.classList.remove('menuOpen');
      mobileMenu.classList.remove('open');
      document.documentElement.classList.remove('prevent-scrolling-mobile-menu');
      document.body.classList.remove('menuOpen');
      window.Tawk_API.showWidget && window.Tawk_API.showWidget();
      if ( windowMenuScrollTop > 0 ) {
        document.documentElement.scrollTop = windowMenuScrollTop;
      }
    } else {
      windowMenuScrollTop = scrollTop(window);
      header.classList.add('menuOpen');
      mobileMenu.classList.add('open');
      document.documentElement.classList.add('prevent-scrolling-mobile-menu');
      document.body.classList.add('menuOpen');
      window.Tawk_API.hideWidget && window.Tawk_API.hideWidget();
    }
  });

  mobileMenu.querySelectorAll('.menu-item-has-children ').forEach( (item) => {
    item.querySelector(':scope > a').addEventListener('click', (e) => {
      e.preventDefault();
      item.classList.toggle('open');
    });
  });
}
window.Tawk_API = window.Tawk_API || {};
window.Tawk_API.onLoad = function(){
  if ( header.classList.contains( 'menuOpen' ) ) {
    window.Tawk_API.hideWidget();
  }
};
