import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

(function($) {


  if( $('.tabbed-call-to-action').length !== 0 ) {
    $('.tabbed-call-to-action').each(function(i, obj) {
      var swiperTabs;
      const swiperTabsEl = $(this).find('.tab-headings')[0];
      if ( swiperTabsEl ) {

        const tabsBP = window.matchMedia( '(min-width: 768px)' );

        const tabsBreakpointChecker = function() {
          if ( tabsBP.matches === true ) {
            // clean up old instances and inline styles when available
            if ( swiperTabs !== undefined ) swiperTabs.destroy( true, true );
                // else if a small viewport and single column layout needed
          } else if ( tabsBP.matches === false ) {
            // fire small viewport version of swiper
            ///////// Tabbed swiper:
            // https://webflow.com/made-in-webflow/website/swiper-tabs
            // How to Slider Setup
            swiperTabs = new Swiper( swiperTabsEl, {
              watchSlidesVisibility: true,
              watchSlidesProgress: true,
              slidesPerView: 'auto',
              spaceBetween: 20,
              direction: 'horizontal',
              centeredSlides: false,
              loop: true,
              freeMode: true,
              watchOverflow: true,
              breakpoints: {
                768: {
                  slidesPerView: 5,
                  spaceBetween: 2,
                  direction: 'vertical',
                }
              }
            });
          }
        }
          // keep an eye on viewport size changes
          tabsBP.addListener(tabsBreakpointChecker);
          // kickstart
          tabsBreakpointChecker();

      }

    });
  }

  if( $('.testimonials-wrapper').length !== 0 ) {
    $('.testimonials-wrapper').each(function(i, obj) {
      var testimonialsSwiper;
      const swiperTabsEl = $(this).find('.testimonial-swiper')[0];
      if ( swiperTabsEl ) {
        testimonialsSwiper = new Swiper( swiperTabsEl, {
          modules: [ Navigation ],
          slidesPerView: 'auto',
          spaceBetween: 16,
          // loopedSlides: 2,
          centeredSlides: true,
          loop: true,
          navigation: {
            nextEl: ".testimonial-next",
            prevEl: ".testimonial-prev",
          },
        });
      }

    });
  }


  if( $('.post-list-wrapper').length !== 0 ) {
    $('.post-list-wrapper').each(function(i, obj) {
      var postListSwiper;
      const swiperTabsEl = $(this).find('.post-swiper')[0];
      if ( swiperTabsEl ) {
        postListSwiper = new Swiper( swiperTabsEl, {
          slidesPerView: 'auto',
          spaceBetween: 0,
        });
      }
      

    });
  }

})(jQuery);