import Swiper from 'swiper';
import { EffectFade } from 'swiper/modules';


(function($) {
  const productSwiper = new Swiper('.product-gallery', {
    // Optional parameters
    modules: [ EffectFade ],
    direction: 'horizontal',
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    }
  });

  productSwiper.on('realIndexChange', function () {
    var index = productSwiper.realIndex;
    var thumbs = $('[data-thumb]');
    var thumb = $(`[data-thumb='${index}']`);
    thumbs.removeClass('active');
    thumb.addClass('active');
  })

  productSwiper.on('autoplayPause', function () {
    $('.slider-controls-dots').addClass('paused');
  } );

  productSwiper.on('autoplayResume', function () {
    $('.slider-controls-dots').removeClass('paused'); 
  } );

  $('.product-gallery .gallery-slide a').on('click', function (e) {
    e.preventDefault();
  });


  $(document).on('click', '[data-thumb]', function (e) {
    var id = this.getAttribute('data-thumb');
    productSwiper.slideToLoop(id);
  });


  $(document).on('click', '[data-gallery-id]', function (e) {
    var id = this.getAttribute('data-gallery-id');
    productSwiper.slideToLoop(id);
  });

  $(document).on( "click", ".wc-quantity-wrapper button.plus, .wc-quantity-wrapper button.minus", ( function() {
    var qty = $(this).closest(".wc-quantity-wrapper").find(".qty");
    var currentQty = parseFloat(qty.val()) || 0;
    var max = parseFloat(qty.attr("max"));
    var min = parseFloat(qty.attr("min"));
    var step = parseFloat(qty.attr("step"));
    $(this).is(".plus") ? max && max <= currentQty ? qty.val(max) : qty.val(currentQty + step) : min && min >= currentQty ? qty.val(min) : currentQty > 0 && qty.val(currentQty - step),
    $(".input-text.qty.text").change()
  } ) );


})(jQuery);


// Facetwp filters 
(function($) {
  function updateFacetSelections() {
    var selections = $("#facet-count");
    const facetQuery = FWP.buildQueryString();
    if ( facetQuery !== '' ) {
      if (selections.hasClass('opacity-0') ) {
        selections.removeClass('opacity-0')
      }
    } else {
      if ( !selections.hasClass('opacity-0') ) {
        selections.addClass('opacity-0')
      }
    }
  }

  function updateFacetSelectedcount() {
    let count = 0;
    for( const [key, value] of Object.entries( FWP.facets ) ) {
      count = count + value.length;
    }
    $("#filter-count").html(count);
  }
  $(function() {
    if ( typeof FWP == 'object' ) {
      updateFacetSelections();
      updateFacetSelectedcount();
      $(document).on('facetwp-loaded', function() {
        updateFacetSelections();
        updateFacetSelectedcount();
      });
  
      $('.shopFiltersBtn').on('click', function() {
        $('#shopFilters').toggleClass('open');
        $('body').toggleClass('filtersOpen');
      });
      $('.shopFiltersClearBtn').on('click', function() {
        FWP.reset();
      });
    }

  });
})(jQuery);
